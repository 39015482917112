<template>
  <div>
    <SideNav />
    <div class="flex flex-col w-full px-5 xl:px-20 prod-area pb-20 md:pb-32">
      <div class="flex top-2-area w-full mx-auto aic jcb">
        <div class="flex w-full py-5 border-b relative mt-4 lg:mt-16">
          <div class="flex aic w-full">
            <div class="flex flex-col ais jcb top-2-text">
              <h2 class="nunitoSans-bold w-full text-gray-800 capitalize">Zusatzbestellung</h2>
              <p class="text-gray-500 w-full fs-12"></p>
            </div>
            <div class="absolute bottom-0 right-0 w-90p h-5p bg-primary"></div>
          </div>
        </div>
      </div>
      <!-- <div class="flex flex-col ais jcb w-full">
        <div class="flex space-x-8 py-1 mt-10 mb-10 md:mb-16 aic w-full xl:max-w-xl">
          <div class="w-full calendar">
            <label
              for="date"
              class="block text-gray-500 text-sm mb-3"
            >{{ $t("Wählen Sie das Startdatum") }}</label>
            <div class="mt-1 relative rounded-md pointer">
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer pointer-events-none fs-16 h-50p rounded-xl px-5 fcc">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 opacity-50"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <date></date>
            </div>
          </div>
        </div>
      </div> -->

      <div class="w-full flex flex-col mt-6 md:mt-8 zusatzbestellung">
        <collapse
          :selected="false"
          class="cat-collapse"
          v-for="(category,key) in categories"
          :key="key"
        >
          <div
            class="text-xs lg:text-sm capitalize flex jcb aic cc-in"
            slot="collapse-header"
          >
            {{category.name}}
            <div class="flex w-100p aic jcb relative h-50p ml-auto mr-12 xl:mr-20">
              <span 
                :class="sumOfPerCategory(category.products) !== 0 ? 'basket-amount w-25p h-25p fcc rounded-md text-secondary bg-gray-200 bg-opacity-80 nunitoSans-bold text-sm':'' "
              >{{sumOfPerCategory(category.products) !== 0 ? sumOfPerCategory(category.products) : ''}}</span>
              <div class="basket-img-layer"><img
                  :src="category.image ? storageUrl + category.image : '/img/dish.webp'"
                  :alt="category.name"
                  class="basket-img relative"
                ></div>
            </div>

            <div class="collapse-header-after"></div>
          </div>

          <div slot="collapse-body">
            <div class="w-full flex flex-wrap ais jcb mx-auto border-t px-2">
              <div
                class="flex flex-col lg:flex-row ais jcb list-items py-3 lg:py-9 border-b"
                v-for="product in category.products"
                :key="product.id"
              >
                <div class="flex h-full w-full lg:w-auto aic justify-between lg:justify-start lg:space-x-5">
                  <img
                    :src="
                      product.image ? storageUrl + product.image : '/img/dish.webp'
                    "
                    :alt="product.name"
                    class="li-img order-2 lg:order-1"
                  >
                  <div class="flex flex-col order-1 lg:order-2">
                    <div class="flex aic space-x-2">
                      <h2 class="text-sm text-gray-800 pi-title capitalize">{{product.name}}</h2>
                      <button
                        @click="()=>{showInfoModal = true;
                      productDescription=product.description;
                      productImage=product.image;
                      productName=product.name}"
                        class="flex  aic jcc w-30p h-30p rounded-full text-primary fs-20"
                      ><i class="fa fa-info"></i></button>
                      <span
                        class="fcc text-green-500 text-xl lg:text-2xl"
                        v-if="product.quantity"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="3"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </span>
                    </div>
                    <!-- <span class="text-gray-500 text-sm lg:text-base">500 g</span> -->
                  </div>
                </div>
                <div class="flex h-full w-full lg:w-auto aic jcb space-x-6">
                  <div class="flex aic jcb space-x-1">
                    <button
                      class="bg-gray-200 bg-opacity-40 p-2 rounded-lg"
                      type="button"
                      @click="remove(product)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18 12H6"
                        />
                      </svg>
                    </button>
                    <input
                      type="text"
                      name="qty"
                      class="w-30p text-center bg-transparent text-sm"
                      :value="product.quantity"
                      readonly
                    />
                    <button
                      class="bg-gray-200 bg-opacity-40 p-2 rounded-lg"
                      type="button"
                      @click="add(product)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </button>
                  </div>
                  <span class="text-sm nunitoSans-bold whitespace-nowrap pr-2 mt-2">{{ product.price}} {{ currency }}</span>
                </div>
              </div>
            </div>
          </div>
        </collapse>
      </div>
      <div class="flex aic jce w-full mt-16 lg:mt-32">
        <button
          type="button"
          @click="goToCheckout"
          class="btn-primary w-full md:max-w-max px-16 h-60p rounded-lg shadow-lg fcc text-white"
        >
          Weitre
        </button>
      </div>
    </div>
    <ProductInfo
      :image="productImage"
      :showInfoModal="showInfoModal"
      :description="productDescription"
      :name="productName"
      @close="showInfoModal = false"
    ></ProductInfo>
  </div>
</template>
<script>
// import MobileCategories from "../components/MobileCategories.vue";
import SideNav from "@/components/SideNav.vue";
import Collapse from "@/components/Collapse.vue";
import ProductInfo from "../components/ProductInfo.vue";

export default {
  components: { Collapse, SideNav, ProductInfo },
  data() {
    return {
      showListPreview: false,
      showInfoModal: false,
      product: null,
      type: null,
      productImage: null,
      productName: null,
      productDescription: null,
    };
  },
  computed: {
    categories() {
      return this.$store.state.orderiomApiPackage.product.categories;
    },
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    basketData() {
      return JSON.parse(localStorage.getItem("basket") || "[]");
    },
    tableName() {
      return this.$route.query.tableName;
    },
    tableId() {
      return this.$route.query.tableId;
    },
    user() {
      return this.$store.state.orderiomApiPackage.auth.user;
    },
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
  },
  methods: {
    add(product) {
      this.$store
        .dispatch("order/addToBasket", {
          productId: product.id,
          attributeItems: [],
          restaurantId: this.restaurantId,
        })
        .then(() => {
          let quantity = product.quantity + 1;
          this.$set(product, "quantity", quantity);
        });
    },
    remove(product) {
      if (product.quantity)
        this.$store
          .dispatch("order/removeFromBasket", {
            productId: product.id,
            restaurantId: this.restaurantId,
          })
          .then(() => {
            let quantity = product.quantity - 1;
            this.$set(product, "quantity", quantity);
          });
    },
    selectCategory(category) {
      this.$store.commit("product/setSelectedCategory", category);
      this.$store.dispatch("product/getProducts", {
        category: category,
        restaurantId: this.restaurantId,
      });
      this.$store.dispatch("product/getAttributeItems", {
        category: category,
        restaurantId: this.restaurantId,
      });
    },
    createBasket() {
      const table = this.tableId
        ? { tableId: this.tableId }
        : this.tableName
        ? { tableName: this.tableName }
        : null;
      const basket = JSON.parse(localStorage.getItem("basket")) || [];
      const basketId = basket.find(
        (basket) => basket.restaurantId == this.restaurantId
      );
      if (!basketId) {
        return this.$store
          .dispatch("order/createBasket", {
            restaurantId: this.restaurantId,
          })
          .then(() => {
            this.$store.dispatch("product/getBasket", this.restaurantId);
            if (table) {
              this.$store.commit("auth/setDeliveryType", "here");
              return this.$store
                .dispatch("order/setTable", {
                  restaurantId: this.restaurantId,
                  ...table,
                })
                .then((res) => {
                  let query = Object.assign({}, this.$route.query);
                  if (query.tableId) delete query.tableId;
                  else if (query.tableName) delete query.tableName;
                  this.$router.replace({ query });
                  if (res.type == "error") {
                    this.$store.commit("setshowNotif", {
                      show: true,
                      msg: res.msg,
                      type: res.type,
                    });
                  }
                });
            }
          });
      }
    },
    createOrder() {
      this.$store
        .dispatch("order/checkAddress", {
          postalCode: this.user.postalCode,
          deliveryType: "delivery",
          restaurantId: this.restaurantId,
        })
        .then((res) => {
          if (res.type == "success") {
            this.$store
              .dispatch("order/createOrder", {
                restaurantId: this.restaurantId,
                basketId: this.basketId,
                paymentType: this.payment,
              })
              .then((res) => {
                if (res.type == "error") {
                  this.$store.commit("setshowNotif", {
                    show: true,
                    msg: res.msg,
                    type: res.type,
                  });
                }
              });
          } else {
            this.$store.commit("setshowNotif", {
              show: true,
              msg: res.msg,
              type: res.type,
            });
          }
        });
    },
    goToCheckout() {
      if (this.ShoppingCart.length) {
        this.$router.push("/dashboard/checkout");
      } else {
        this.$store.commit("setshowNotif", {
          show: true,
          msg: "You cannot go to checkout.Your basket is empty!",
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.createBasket();
    this.$store.dispatch(
      "product/getCategoriesWithProducts",
      this.restaurantId
    );
  },
  destroyed() {
    this.$store.commit("product/setCategories", []);
  },
};
</script>
